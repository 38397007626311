body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    padding: 0;
    margin: 0;
}

.imageCss {
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    width: 100%;
    height: 100vh;
}